// This component has been migrated to V3
// If you make changes to this component, please drop a note in #lp-instui-react-upgrade
export default {
  rootPath: () => '/',
  homePath: () => '/home',
  guidelinesPath: () => '/guidelines',
  comparisonPath: () => '/comparison',
  storePath: () => '/store',
  setupPath: () => '/setup',
  contactSupport: () => '/my_settings/contact-support',
  pageNotFoundPath: () => '/public/404.html',
};
