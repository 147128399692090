// This component has been migrated to V3
// If you make changes to this component, please drop a note in #lp-instui-react-upgrade
export default {
  analysisPath: () => '/analysis',
  analysisUsagePath: () => '/analysis/usage',
  analysisTrialsPath: () => '/analysis/trials',
  analysisTrialPath: id => `/analysis/trials/${id}`,
  analysisUsageProductPath: id => `/analysis/usage/${id}`,
  analysisNewTrialPath: () => `/analysis/trials/new`,

  analysisRCEPath: () => '/analysis/rce',
  analysisEditImpactAnalysisPath: id => `/analysis/rce/${id}`,
  analysisAllRCEPath: () => '/analysis/rces',
  analysisImpactAnalysisByToolPath: id => `/analysis/rces/${id}`,

  analysisImpactAnalysisReportUsagePath: id => `/analysis/rce/${id}/usage`,
  analysisImpactAnalysisReportCostPath: id => `/analysis/rce/${id}/cost`,

  analysisImpactAnalysisReportFeedbackPath: id => `/analysis/rce/${id}/feedback`,
  analysisOverallGradingFeedback: id => `/analysis/rce/${id}/feedback/overall`,
  analysisProductUsageGradingFeedback: id => `/analysis/rce/${id}/feedback/product_usage`,
  analysisSchedulingGradingFeedback: id => `/analysis/rce/${id}/feedback/scheduling`,
  analysisAddedValueGradingFeedback: id => `/analysis/rce/${id}/feedback/added_value`,

  analysisImpactAnalysisReportMethodologyPath: id => `/analysis/rce/${id}/methodology`,

  analysisNewRCEWizardPath: (step = 'welcome') => `/analysis/rce/new/${step}`,
  analysisEditRCEWizardPath: id => `/analysis/rce/${id}`,
  analysisEditWithStepRCEWizardPath: (id, step) => `/analysis/rce/${id}/${step}`,

  analysisFeedbackReportPath: () => '/analysis/feedback_report',
};
